import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import md5 from 'js-md5'
import './permission'
import './stylus/element.ui.styl' // 覆盖原element ui 样式
import './stylus/index.styl'
import TEditor from '@/components/editor.vue'

import './theme/index.css'
import Element from 'element-ui'

import { toast, load } from './utils/box'
Vue.component('TEditor', TEditor)
const { MessageBox, Loading } = Element

Vue.use(Element)

Vue.prototype.$md5 = md5
Vue.prototype.$toast = toast(MessageBox)
Vue.prototype.$load = load(Loading)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
