<template>
  <div id="login">
    <div class="title blod">电力施工智慧工地系统 V3.0</div>
    <div class="copyright">
      Copyright ©版权所有 2019-2020 杭州智网互联科技有限公司
      <span class="tiny-hand">浙ICP备16006642号-2</span>
    </div>

    <div ref='sign' class="sign-in">
      <div class="line"></div>
      <div class="name blod">智慧工地</div>

      <div class="formData">
        <template v-for="item in signIn">
          <div :key="item.type" class="item-input">
            <img
              class="icon"
              :src="require(`@/assets/login/${item.icon}`)"
              alt=""
            />
            <input :placeholder="item.placeholder" v-model="$data[item.key]" class="reg" :type="item.type" />
          </div>
        </template>
        <div class="ver-code">
          <div>
            <img
              class="icon"
              :src="require(`@/assets/login/code.png`)"
              alt=""
            />
            <input v-model="code" placeholder="请输入验证码" type="text" />
          </div>
          <img @click="_loadData"  class="tiny-hand code" :src="verifyData.src" alt="">
        </div>
      </div>
      <div ref='submit'  @click="userSignIn" class="submit tiny-hand reg  hover button">登录</div>
    </div>
  </div>
</template>

<script>
import { debounce, throttle } from '@/utils/util'
import { getVerifyCode, gotoSignIn, getAuthRoute } from '@/api/login'
export default {
  name: 'Login',
  data() {
    return {
      signIn: [
        { icon: 'username.png', type: 'text', key: 'username', placeholder: '请输入用户名' },
        { icon: 'password.png', type: 'password', key: 'password', placeholder: '请输入密码' },
      ],
      verifyData: {},
      username: 'admin',
      password: '123456',
      code: '',
    }
  },
  created() {
    this._loadData()
  },

  mounted() {
    window.addEventListener('keyup', throttle(this.userKeyDown))
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.userKeyDown)
  },
  methods: {
    _loadData: debounce(function() {
      getVerifyCode().then(res => {
        // console.log(res, '则是：')
        const { verify_src: src, verify_id: id } = res.result
        this.verifyData = { src, id }
      })
    }),

    // 监听键盘事件
    userKeyDown: function(event) {
      const { keyCode } = event
      if (keyCode === 13) this.userSignIn()
      else return false
    },

    // 用户登录
    userSignIn: debounce(function() {
      if (!this.username) {
        this.$toast('请输入用户名')
        return false
      } else if (!this.password) {
        this.$toast('请输入密码')
        return false
      } else if (!this.code) {
        this.$toast('请输入验证码')
        return false
      }
      const load = this.$load(this.$refs.sign)
      gotoSignIn({
        username: this.username,
        password: this.$md5(this.password),
        verify_code: this.code,
        verify_id: this.verifyData.id,
      }).then(res => {
        load.close()
        if (res.status !== 200) {
          this.$toast(res.message)
          this._loadData()
          return false
        }
        this.$store.dispatch('user/changeUser', res.result)
          .then(res => {
            // console.log('在')
            // console.log(this.$store)
            // this.gotoHome()
            // console.log('000')
            this.$router.push({ name: 'ProjectList' })
          })
      })
    }),

    // 跳转至首页
    gotoHome: function() {
      const { USER_ID } = this.$store.getters
      getAuthRoute(USER_ID).then(res => {
        console.log(res, '这是路由')
      })
    },
  },
}
</script>

<style lang="stylus" scoped>
$width = 340px
$height = 36px

#login {
  width: 100%;
  height: 100%;
  background-image: url('../assets/login/bc.png');
  background-size: 100% 100%;
}

.title {
  color: $Color;
  font-size: $Titlesize;
  position: fixed;
  top: 34px;
  left: 34px;
}

.copyright {
  color: #fff;
  font-size: $Fontsize;
  position: fixed;
  bottom: 24px;
  width: 100%;
  text-align: center;
}

.sign-in {
  width: 473px;
  height: 400px;
  border: 1px solid #2ECFBF;
  position: fixed;
  top: calc(((100% - 400px) / 2));
  left: calc(((100% - 473px) / 2));
  border-radius: 4px;
  background-color: $Scolor;
  margin-bottom 26px;

  .line {
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0;
    height: 6px;
    background-color: $Dcolor;
  }

  .name {
    height: 80px;
    line-height: 120px;
    font-size: $Bsize;
    color: $Dcolor;
    text-align: center;
    margin-bottom: 39px;
  }

  .formData {
    input {
      outline: none;
      border: none;
      width: 90%;
      height: 100%;
      color: $Bcolor;
      font-size: $Fontsize;
    }
    // input::-webkit-input-placeholder{
    //   color: $Bcolor;
    //   font-size: $Fontsize;
    // }

    .item-input {
      width: $width;
      height: $height;
      margin: 0 auto;
      margin-bottom: 24px;
      background-color: #fff;
      border: 1px solid #DCDEE2;
      border-radius: 3px;
      display: flex;
      align-items: center;
    }
    .icon {
      width: 16px;
      height: 16px;
      margin: 0 8px;
    }

    .ver-code {
      background-color: none !importent;
      border: none;
      width: $width;
      height: $height;
      margin: 0 auto;
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      justify-content space-between;
      div {
        &:nth-of-type(1) {
          width: 211px;
          border: 1px solid #DCDEE2;
          border-radius: 3px;
          height 100%;
          display flex;
          align-items center;
          background-color #fff
        }
      }
      .code {
        width: 120px;
        height: 36px;
      }
    }
  }

  .submit {
    width $width;
    height 40px;
    margin 0 auto;
    border: 1px solid #2DD5C4;
    border-radius: 3px;
    font-size: $Fontsize;
    line-height: 40px;
    color: #FFFFFF;
    text-align center;
  }
}
</style>
