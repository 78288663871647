
/**
 * 提示弹窗
 * @param {MessageBox} self element-ui 中的 MessageBox 实例
 */
const toast = self => {
  return function (title) {
    self.alert(title, '提示', {
      distinguishCancelAndClose: true, // 显示遮罩层
      showClose: false, // 关闭右上角 x 图标
    })
  }
}

const load = self => {
  return function (dom) {
    return self.service({
      target: dom,
      lock: true,
      text: '加载中...',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)',
    })
  }
}

export {
  toast,
  load,
}
