import Cookies from 'js-cookie'

const key = 'zw-token'

const getToken = () => {
  return Cookies.get(key)
}
const setToken = value => {
  return Cookies.set(key, value)
}
const removeToken = () => {
  return Cookies.remove(key)
}

export {
  getToken,
  setToken,
  removeToken,
}
