<template>
  <el-scrollbar wrap-class="scrollbar-wrapper" id="aside-z" style="width: 200px">
    <div class="logo reg" >杭州智网互联科技有限公司</div>
    <el-menu
      v-if='menu.length'
      :default-active="selectActive"
      class="el-menu-vertical-demo menu"
      :collapse="false"
      @select='gotoProject'
    >
    <template v-for="(item, index) in menu">
        <el-menu-item v-if="item.children && item.children.length === 1" :index='item.children[0].name' :key='index'>
          <i class="el-icon-setting"></i>
          <span slot="title">{{ item.meta.title }}</span>
        </el-menu-item>

        <el-submenu v-else :key="index" :index='item.name'>
          <template slot="title">
            <i class="el-icon-location"></i>
            <span slot="title">{{ item.meta.title }}</span>
          </template>

           <template v-for='(sub, ind) in item.children'>
             <el-menu-item v-if='!sub.children || (sub.children && sub.children.length === 1)' :key="ind" :index='sub.children ? sub.children[0].name : sub.name '>{{ sub.meta.title }}</el-menu-item>

             <el-submenu v-else :index='sub.name' :key="ind">
               <span slot="title" >{{ sub.meta.title }}</span>
               <el-menu-item v-for='(s, i) in sub.children' :key="i" :index="s.name">{{ s.meta.title }}</el-menu-item>
             </el-submenu>

           </template>
        </el-submenu>
    </template>
    </el-menu>
  </el-scrollbar>
</template>

<script>
export default {
  name: 'aside',
  data () {
    return {
    }
  },
  methods: {
    gotoProject: function(index) {
      // this.selectActive = index
      this.$store.commit('router/SET_ACTIVE', index) // 去对应页

      this.$router.push({ name: index })
    },
  },
  computed: {
    menu() {
      return this.$store.getters.ROUTER_ARR
    },
    selectActive() {
      return this.$store.getters.ROUTER_INDEX
    },
  },
  watch: {
    menu(newV) {
      console.log(newV, '更新了。。')
    },
  },
}
</script>

<style lang="stylus" scoped>
#aside-z {
  height: 100%;
  width 10%;
  background-color: #001529;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 199px;
  min-height: 400px;
}
.menu {
  background-color #001529;
  color #fff;
  border none;
}
.logo{
  width 80%;
  height 64px;
  padding-left 10%;
  color #fff;
  text-align center;
  line-height 64px;
  text-overflow ellipsis;
  white-space nowrap;
  overflow hidden;
  font-size: $Fsize;
}
</style>
