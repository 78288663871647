const config = require('./config')

class PublicBaseUrl {
  constructor() {
    this.baseUrl = {
      development: '/api',
      production: config.TRUE_BASE_URL,
    }
  }

  getBaseUrl() {
    return this.baseUrl[process.env.NODE_ENV]
  }
}

export { PublicBaseUrl }
