import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/cookie'
import router from './router'
import store from './store'

NProgress.configure({ showSpinner: false })

router.beforeEach((to, from, next) => {
  const t = getToken()

  const list = store.getters.ROUTER_ARR

  NProgress.start()

  // console.log(router.options)
  if (t) {
    if (to.path === '/') {
      next({ name: 'ProjectList' })
      NProgress.done()
    } else {
      store.commit('router/SET_ACTIVE', to.name) // 去对应页
      if (list.length) {
        next()
      } else {
        store.dispatch('router/setRouter', router.options.routes, [])
        next()
      }
    }
  } else {
    if (to.path !== '/') {
      next({
        name: 'Login',
        redirect: to.path,
      })
      NProgress.done()
    } else {
      next()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
