<template>
  <el-container id='home-z'>
    <ASIDE></ASIDE>
    <HEADER></HEADER>
  </el-container>
</template>

<script>
import ASIDE from './components/aside'
import HEADER from './components/header'
export default {
  name: 'Home',
  components: {
    ASIDE,
    HEADER,
  },
}
</script>

<style lang="stylus" scoped>
#home-z {
  background-color #F5F7F9;
  width 100%;
  height 100%;
  display flex;
  justify-content space-between;
}
</style>
