
const state = {
  asideArr: [], // 路由地址
  asideIndex: 'ProjectList', // 左边导航栏选中搜索
}

const mutations = {
  SET_ASIDE: function(state, list) {
    state.asideArr = list
  },
  SET_ACTIVE: function(state, index) {
    state.asideIndex = index
  },
}

const actions = {
  setRouter: function({ commit }, routes, roles) {
    return new Promise(resolve => {
      // console.log(roles, '这是权限列表')
      // console.log(routes, '这是路由列表')
      commit('SET_ASIDE', routes.filter(item => item.path !== '/' && item.path !== '*'))
      // resolve(!routes[0].children ? routes[0].name : routes[0].children[0].name)
      resolve()
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
