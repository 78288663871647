import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import router from './modules/router'
import getters from './getters'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    is_background: true, // 是否显示 背景颜色
    bc_color: '', // 背景颜色
  },
  mutations: {
    SET_BC: function(state, bc) {
      state.is_background = bc
    },
    SET_COLOR: function(state, c) {
      state.bc_color = c
    },
  },
  actions: {
  },
  modules: {
    user,
    router,
  },
  getters,
})
