
/**
 * 获取随机字符串
 * @param {Number} len 获取随机字符的长度
 */
const randomStr = (len = 32) => {
  const list = Array.from('qwertyuiopasdfghjklzxcvbnmQAZXSWEDCVFRTGBNHYUJMKIOLP2597846130')
  let str = ''

  for (let i = 0; i < len; i++) {
    str += list[Math.round(Math.random() * list.length)]
  }

  return str
}

/**
 * 立即执行版 防抖
 * @param {function} fn 方法
 * @param {number} wait 等待时间
 */
const debounce = (fn, wait = 500) => {
  let timer = null
  let run = true

  return function() {
    if (timer) {
      clearTimeout(timer)
      this.$toast('请勿操作频繁')
    }

    run = !timer

    timer = setTimeout(() => {
      timer = null
    }, wait)

    if (run) fn.apply(this, arguments)
  }
}

/**
 * 定时器版 节流
 * @param {function} fn 方法
 * @param {number} wait 等待时间
 */
const throttle = (fn, wait = 500) => {
  let timer = null
  return function() {
    if (!timer) {
      timer = setTimeout(() => {
        timer = null
        fn.apply(this, arguments)
      }, wait)
    }
  }
}

/**
 * 将时间格式清洗为 年-月-日
 * @param {Array} arr 时间数组
 */
const dateMap = arr => {
  if (!arr.length) return ''
  else {
    return arr.map(d => {
      const year = d.getFullYear()
      const month = d.getMonth() + 1
      const day = d.getDate()
      const hours = d.getHours()
      const minutes = d.getMinutes()
      const seconds = d.getSeconds()
      return [year, month, day].map(n => padStart(n)).join('-') + ' ' + [hours, minutes, seconds].map(n => padStart(n)).join(':')
    })
  }
}

const padStart = n => {
  n = n.toString()
  return n.padStart(2, '0')
}

/**
 * 转为日期对象
 * @param {String} cur 日期 2020-01-07 17:26:00
 */
const dateObj = cur => {
  const date = cur.split(' ')[0].split('-')
  const time = cur.split(' ')[1].split(':')

  return new Date(date[0], Number(date[1]) - 1, date[2], time[0], time[1], time[2])
}

/**
 * 下载 excel
 * @param {Object} params header: 表头, data: 数据, name: 文件名, title: 字段
 */
const downloadExcel = params => {
  const { header, data, name, title = [] } = params
  import('@/utils/Export2Excel').then((excel) => {
    excel.export_json_to_excel({
      header, // 表头 必填
      data: title.length ? clearData(data, title) : data, // 具体数据 必填
      filename: name, // 非必填
      autoWidth: true, // 非必填
      bookType: 'xlsx', // 非必填
    })
  })
}

/**
 * 清洗数据
 * @param {Array} list 数据列表
 * @param {Array} title 字段名
 */
const clearData = (list, title) => {
  return list.map(r => title.map(s => r[s]))
}

export {
  randomStr,
  debounce,
  throttle,
  dateMap,
  dateObj,
  downloadExcel,
}
