<template>
  <el-container id='header-z'>
    <el-header class="title">
      <div>
        <!-- <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ name: 'ProjectList' }">工程管理</el-breadcrumb-item>
          <el-breadcrumb-item>工程列表</el-breadcrumb-item>
        </el-breadcrumb> -->
      </div>
      <div><span>Man</span></div>
    </el-header>

    <!-- <MAIN></MAIN> -->
    <MAIN></MAIN>
  </el-container>
</template>

<script>
import MAIN from './main'
export default {
  name: 'header',
  components: {
    MAIN,
  },
}
</script>

<style lang="stylus" scoped>
#header-z{
  // height 100%;
  width: 80%
  .title {
    display flex;
    align-items center;
    justify-content space-between;
    height 64px;
    background-color #fff;
    // position fixed;
    // top 0;
    // left 0;
  }
}

</style>
