import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Home from '@/views/home'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/project',
    name: 'Project',
    component: Home,
    meta: { title: '工程管理', auth: 'project/manage' },
    children: [
      {
        path: 'list',
        name: 'ProjectList',
        component: () => import('@/views/project/List.vue'),
        meta: { title: '工程列表', auth: 'project/list', index: '0-2' },
      },
      {
        path: 'fence',
        name: 'Fence',
        component: () => import('@/views/project/Fence'),
        meta: { title: '工程围栏', auth: 'project/fence' },
      },
      {
        path: 'deepmanage',
        name: 'DeepManage',
        component: () => import('@/views/project/DeepManage'),
        meta: { title: '深基坑管理', auth: 'project/deepManage' },
      },
      {
        path: 'towermanage',
        name: 'TowerManage',
        component: () => import('@/views/project/TowerManage'),
        meta: { title: '杆塔管理', auth: 'project/towerManage' },
      },
      {
        path: 'duty',
        name: 'Duty',
        component: () => import('@/views/project/Duty'),
        meta: { title: '到岗到位', auth: 'project/duty' },
      },
      {
        path: 'idmanage',
        name: 'IdManage',
        component: () => import('@/views/project/IdManage'),
        meta: { title: '证件管理', auth: 'project/idManage' },
      },
      {
        path: 'workticket',
        name: 'WorkTicket',
        component: () => import('@/views/project/WorkTicket'),
        meta: { title: '工作票管理', auth: 'project/workTicket' },
      },
    ],
  },
  {
    path: '/user',
    name: 'User',
    component: Home,
    meta: { title: '用户管理', auth: 'user/manage' },
    children: [
      {
        path: 'list',
        name: 'UserList',
        component: () => import('@/views/user/List.vue'),
        meta: { title: '用户列表', auth: 'user/list' },
      },
      {
        path: 'unitmanage',
        name: 'UnitManage',
        component: () => import('@/views/user/UnitManage.vue'),
        meta: { title: '单位管理', auth: 'user/unitManage' },
      },
    ],
  },
  {
    path: '/device',
    name: 'Device',
    component: Home,
    meta: { title: '设备管理', auth: 'device/manage' },
    children: [
      {
        path: 'list',
        name: 'DeviveList',
        component: () => import('@/views/device/List'),
        meta: { title: '设备列表', auth: 'device/list' },
      },
      {
        path: 'deviceconfig',
        name: 'DeviceConfig',
        component: () => import('@/views/device/DeviceConfig'),
        meta: { title: '设备配置', auth: 'device/deviceConfig' },
      },
    ],
  },
  {
    path: '/bind',
    name: 'Bind',
    component: Home,
    meta: { title: '设备绑定', auth: 'device/bind' },
    children: [
      {
        path: 'indbind',
        name: 'IndBind',
        component: () => import('@/views/bind/IndBind'),
        meta: { title: '单兵绑定', auth: 'bind/indBind' },
      },
      {
        path: 'handbind',
        name: 'HandBind',
        component: () => import('@/views/bind/HandBind.vue'),
        meta: { title: '手环绑定', auth: 'bind/handBind' },
      },
      {
        path: 'videobind',
        name: 'VideoBind',
        component: () => import('@/views/bind/VideoBind.vue'),
        meta: { title: '摄像头绑定', auth: 'bind/videoBind' },
      },
      {
        path: 'gasbind',
        name: 'GasBind',
        component: () => import('@/views/bind/GasBind.vue'),
        meta: { title: '气体绑定', auth: 'bind/gasBind' },
      },
      {
        path: 'surbind',
        name: 'SurBind',
        component: () => import('@/views/bind/SurBind.vue'),
        meta: { title: '环境绑定', auth: 'bind/surBind' },
      },
      {
        path: 'facebind',
        name: 'FaceBind',
        component: () => import('@/views/bind/FaceBind.vue'),
        meta: { title: '人脸绑定', auth: 'bind/faceBind' },
      },
    ],
  },
  {
    path: '/monitor',
    component: Home,
    name: 'Monitor',
    meta: { title: '监控中心', auth: 'monitor/center' },
    children: [
      {
        path: 'videomonitor',
        name: 'VideoMonitor',
        meta: { title: '视频监控', auth: 'monitor/videoMonitor' },
        component: () => import('@/views/monitor/VideoMonitor'),
      },
      {
        path: 'devicelocation',
        name: 'DeviceLocation',
        meta: { title: '单兵位置', auth: 'monitor/deviceLocation' },
        component: () => import('@/views/monitor/DeviceLocation'),
      },
      {
        path: 'videomanage',
        name: 'VideoManage',
        meta: { title: '视频管理', auth: 'monitor/videoManage' },
        component: () => import('@/views/monitor/videoManage/List'),
        children: [
          {
            path: 'videolist',
            name: 'VideoList',
            meta: { title: '视频列表', auth: 'monitor/videoManage/videoList' },
            component: () => import('@/views/monitor/videoManage/VideoList'),
          },
          {
            path: 'videotype',
            name: 'VideoType',
            meta: { title: '视频分类', auth: 'monitor/videoManage/videoType' },
            component: () => import('@/views/monitor/videoManage/VideoType'),
          },
        ],
      },
      {
        path: 'imgmanage',
        name: 'ImgManage',
        meta: { title: '照片管理', auth: 'monitor/imgManage' },
        component: () => import('@/views/monitor/imgManage/List'),
        children: [
          {
            path: 'imglist',
            name: 'ImgList',
            meta: { title: '照片列表', auth: 'monitor/imgManage/imgList' },
            component: () => import('@/views/monitor/imgManage/ImgList'),
          },
          {
            path: 'imgtype',
            name: 'ImgType',
            meta: { title: '照片分类', auth: 'monitor/imgManage/imgtype' },
            component: () => import('@/views/monitor/imgManage/ImgType.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/audit',
    component: Home,
    name: 'Audit',
    meta: { title: '稽查管理', auth: 'audit/manage' },
    children: [
      {
        path: 'manage',
        name: 'AuditManage',
        component: () => import('@/views/audit/List.vue'),
      },
    ],
  },
  {
    path: '/daily',
    component: Home,
    name: 'Daily',
    meta: { title: '日报管理', auth: 'daily/manage' },
    children: [
      {
        path: 'manage',
        name: 'DailyManage',
        component: () => import('@/views/daily/List.vue'),
      },
    ],
  },
  {
    path: '/face',
    component: Home,
    name: 'Face',
    meta: { title: '人脸识别', auth: 'face/rec' },
    children: [
      {
        path: 'list',
        name: 'FaceList',
        component: () => import('@/views/face/List.vue'),
        meta: { title: '人脸库', auth: 'face/list' },
      },
      {
        path: 'reclog',
        name: 'recLog',
        component: () => import('@/views/face/RecLog.vue'),
        meta: { title: '识别记录', auth: 'face/recLog' },
      },
    ],
  },
  {
    path: '/safety',
    name: 'Safety',
    component: Home,
    meta: { title: '安全管理', auth: 'safety/manage' },
    children: [
      {
        path: 'learn',
        name: 'Learn',
        meta: { title: '安全学习', auth: 'safety/learn' },
        component: () => import('@/views/safety/learn/List'),
        children: [
          {
            path: 'content',
            name: 'Content',
            meta: { title: '学习内容', auth: 'safety/learn/content' },
            component: () => import('@/views/safety/learn/Content'),
          },
          {
            path: 'type',
            name: 'Type',
            meta: { title: '学习分类', auth: 'safety/learn/type' },
            component: () => import('@/views/safety/learn/Type'),
          },
          {
            path: 'check',
            name: 'Check',
            meta: { title: '学习稽查', auth: 'safety/learn/check' },
            component: () => import('@/views/safety/learn/Check'),
          },
        ],
      },
      {
        path: 'exam',
        name: 'Exam',
        meta: { title: '安全考试', auth: 'safety/exam' },
        component: () => import('@/views/safety/exam/List'),
        children: [
          {
            path: 'testpaper',
            name: 'TestPaper',
            meta: { title: '试卷管理', auth: 'safety/exam/testpaper' },
            component: () => import('@/views/safety/exam/TestPaper'),
          },
          {
            path: 'testques',
            name: 'TestQues',
            meta: { title: '考题管理', auth: 'safety/exam/testques' },
            component: () => import('@/views/safety/exam/TestQues'),
          },
          {
            path: 'testpapertype',
            name: 'TestPaperType',
            meta: { title: '试卷分类', auth: 'safety/exam/testpapertype' },
            component: () => import('@/views/safety/exam/TestPaperType'),
          },
          {
            path: 'testquestype',
            name: 'TestQuesType',
            meta: { title: '考题分类', auth: 'safety/exam/testquestype' },
            component: () => import('@/views/safety/exam/TestQuesType'),
          },
        ],
      },
    ],
  },
  {
    path: '/system',
    name: 'System,',
    component: Home,
    meta: { title: '系统管理', auth: 'system/manage' },
    children: [
      {
        path: 'authmanage',
        name: 'AuthManage',
        component: () => import('@/views/system/authManage/List'),
        meta: { title: '权限管理', auth: 'system/authManage/list' },
        children: [
          {
            path: 'rolemanage',
            name: 'RoleManage',
            component: () => import('@/views/system/authManage/RoleManage'),
            meta: { title: '角色管理', auth: 'system/authManage/roleManage' },
          },
          {
            path: 'adminlist',
            name: 'AdminList',
            component: () => import('@/views/system/authManage/AdminList'),
            meta: { title: '管理员列表', auth: 'system/authManage/adminList' },
          },
          {
            path: 'authrules',
            name: 'AuthRules',
            component: () => import('@/views/system/authManage/AuthRules'),
            meta: { title: '权限规则', auth: 'system/authManage/authRules' },
          },
        ],
      },
      {
        path: 'dict',
        name: 'Dict',
        component: () => import('@/views/system/Dict.vue'),
        meta: { title: '字典管理', auth: 'system/dict' },
      },
      {
        path: 'appwx',
        name: 'AppWx',
        component: () => import('@/views/system/AppWx.vue'),
        meta: { title: 'APP/小程序', auth: 'system/appWx' },
      },
    ],
  },
  { path: '*', redirect: '/', hidden: true },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

// Error: Redirected when going from “/l“ to “/ProjectList“ via a navigation guard
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

export default router
