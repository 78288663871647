import { PublicAxios } from '@/utils/publicAxios'
const $ = new PublicAxios()

// --------------------------------------------
// 登录API接口
// --------------------------------------------

/**
 * 获取验证码图片
 */
const getVerifyCode = async () => {
  return await $.get({
    url: 'admin/AdminLogin/verify',
  })
}

/**
 * 登录
 * @param {Object} data 具体参数详看接口
 */
const gotoSignIn = async data => {
  return await $.post({
    url: 'admin/AdminLogin/login',
    data,
  })
}

/**
 * 获取当前用户的路由权限
 * @param {String} id 用户id
 */
const getAuthRoute = async id => {
  return await $.post({
    url: 'admin/AdminMy/myinfo',
    data: { admin_user_id: id },
  })
}

export {
  getVerifyCode,
  gotoSignIn,
  getAuthRoute,
}
