import axios from 'axios'
import { PublicBaseUrl } from './publicBaseUrl'
import { load } from '@/utils/box'
import Element from 'element-ui'
const { Loading } = Element

var dom = null
class PublicAxios extends PublicBaseUrl {
  constructor() {
    super()

    this.instance = axios.create({
      baseURL: this.getBaseUrl(),
    })

    // this.load = null

    this.instance.interceptors.request.use(this._request)
    this.instance.interceptors.response.use(this._response, this._resErr)
  }

  // 请求拦截
  _request(req) {
    // req.headers['Content-Type'] = 'multipart/form-data'
    // console.log(req, '请求前')
    return req
  }

  // 响应拦截
  _response(res) {
    dom && dom.close && dom.close()
    if (res.status === 200) {
      if (res.data && res.data.errcode === 404) {
        return new PublicAxios().againAxios(res.config)
      } else {
        return res.data
      }
    }
  }

  // 响应失败
  _resErr(err) {
    console.log(err, '这是错误殴打')
  }

  async get(params = {}) {
    const { url = '', data = {}, isLoad = false } = params
    this.showLoading(isLoad)
    return await this.instance.get(url, { params: data })
  }

  async post(params = {}) {
    const { url = '', data = {}, isLoad = false } = params
    this.showLoading(isLoad)
    return await this.instance.post(url, data)
  }

  async upload(params = {}) {
    const { url = '', file = {} } = params
    const data = new FormData()
    data.append('file', file)
    const config = { headers: { 'Content-Type': 'multipart/form-data' } }
    return await this.instance.post(url, data, config)
  }

  // 404页面、切换域名、重新请求
  againAxios(config) {
    const { url, data, method, params, baseURL } = config
    this.instance.defaults.baseURL = baseURL === '/mock' ? '/api' : '/mock'

    let obj = data || params
    if (/{/ig.test(data)) {
      obj = JSON.parse(data)
    }

    return this[method]({ url, data: obj })
  }

  // 显示加载loading框、只适用于 右边数据布局
  showLoading(isLoad) {
    dom && dom.close && dom.close()
    isLoad && (dom = load(Loading)(document.getElementById('main-z')))
  }
}

export { PublicAxios }
