import { setToken } from '@/utils/cookie'
const state = {
  userid: '', // 用户 id
  userinfo: {}, // 用户 信息
  firstRoute: '', // 首页路由
}

const mutations = {
  SET_USERINFO: function(state, params) {
    console.log(params)
  },
  SET_USERID: function(state, id) {
    state.userid = id
  },
  SET_FIRST_ROUTE: function(state, url) {
    state.firstRoute = url
  },
}

const actions = {
  changeUser: function({ commit }, data) {
    return new Promise(resolve => {
      const { admin_token: token, admin_user_id: id } = data
      setToken(token)
      commit('SET_USERID', id)
      resolve()
    })
  },
  changeRoute: function({ commit }, r) {
    commit('SET_FIRST_ROUTE', r)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
