<template>
  <el-scrollbar wrap-class="scrollbar-wrapper" id="main-z" :class="isBc && 'color'" :style="`width: 98%; height: 100%;background-color:${color}`">

  <el-main style="padding: 10px; height:100%;">
    <router-view></router-view>
  </el-main>
   </el-scrollbar>
</template>

<script>
export default {
  name: 'main',
  computed: {
    isBc() {
      return this.$store.getters.ISBC
    },
    color() {
      return this.$store.getters.COLOR
    },
  },
}
</script>

<style lang="stylus" scoped>
#main-z {
  width 98%;
  height 90%;
  border-radius 4px;
  margin 1%;
}
.color {
  background-color #fff;
}
</style>
